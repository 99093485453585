import { InvoiceCar, InvoicePericiaItem } from "../../models/invoice/invoice.model";
import { InvoiceCarRepository, InvoiceDTO, InvoicePericiaItemRepository, InvoiceToInsert } from "../../models/invoice/invoice.repository";
import { calculateIVA, currencyMask, formatIntegerToCurrency } from "../helpers/numbers";

export const invoiceToInsertFactory = (i: InvoiceDTO): InvoiceToInsert => {
  const total_pericias = i.pericias.length;
  const total_price = i.pericias.reduce((acc, curr) => acc + curr.getCustomInsurancePrice(), 0);

  return {
    costumer_id: i.costumerId,
    total_pericias,
    total_price,
    payed: false,
    payed_at: null,
    payed_value: 0,
    start_date: i.startDate,
    end_date: i.endDate
  };
};

export const invoiceCarFactory = (car: InvoiceCarRepository): InvoiceCar => ({
  model: car.model,
  brand: car.brand,
  color: car.color,
  plate: car.plate,
  chassisNumber: car.chassis_number,
  insuranceName: car.insurance_name
});

export const invoicePericiaItemFactory = (item: InvoicePericiaItemRepository): InvoicePericiaItem => ({
  totalPrice: item.total_price,
  unmountPrice: item.unmount_price,
  addtionalCost: item.addtional_cost,
  totalHours: item.total_hours,
  costumerPrice: item.costumer_price,
  insuranceHours: item.insurance_hours,
  pricePerHour: item.price_per_working_hour,
  getPrice: invoicePericiaItemMethods.getPrice,
  getCurrencyPrice: invoicePericiaItemMethods.getCurrencyPrice,
  getCurrencyPriceWithIVA: invoicePericiaItemMethods.getCurrencyPriceWithIVA,
  getCustomInsurancePrice: invoicePericiaItemMethods.getCustomInsurancePrice,
  getCurrencyCustomInsurancePrice: invoicePericiaItemMethods.getCurrencyCustomInsurancePrice,
  getCurrencyCustomInsurancePriceWithIVA: invoicePericiaItemMethods.getCurrencyCustomInsurancePriceWithIVA
});

const invoicePericiaItemMethods = {
  getPrice: function (this: InvoicePericiaItem) {
    if (this.costumerPrice > 0) {
      return this.costumerPrice;
    }

    const price = this.totalPrice;
    return this.unmountPrice + price + this.addtionalCost;
  },

  getCurrencyPrice: function (this: InvoicePericiaItem) {
    return currencyMask(formatIntegerToCurrency(this.getPrice()));
  },

  getCurrencyPriceWithIVA: function (this: InvoicePericiaItem) {
    return currencyMask(formatIntegerToCurrency(calculateIVA(this.getPrice())));
  },

  getCustomInsurancePrice: function (this: InvoicePericiaItem) {
    if (this.insuranceHours === 0) return this.getPrice();

    const price = this.insuranceHours * this.pricePerHour;
    return this.unmountPrice + price + this.addtionalCost;
  },

  getCurrencyCustomInsurancePrice: function (this: InvoicePericiaItem) {
    if (!this.insuranceHours || this.insuranceHours === 0) {
      return this.getCurrencyPrice();
    }

    return currencyMask(formatIntegerToCurrency(this.getCustomInsurancePrice()));
  },

  getCurrencyCustomInsurancePriceWithIVA: function (this: InvoicePericiaItem) {
    if (this.insuranceHours === 0) return this.getCurrencyPriceWithIVA();

    return currencyMask(formatIntegerToCurrency(calculateIVA(this.getCustomInsurancePrice())));
  }
};
